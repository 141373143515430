import request from "@/utils/request"

export default class ApiDesign {
  /**
   * 获取模型数据接口
   */
  static getModule({ id, noTranslate }) {
    return request({
      url: `api/product/product/${id}`,
      method: "GET",
      params: {
        noTranslate
      }
    })
  }
  // 加入橱窗
  static addShowcase(data) {
    return request({
      url: "api/v2/user/design/add",
      method: "POST",
      data
    })
  }
  // 更新橱窗
  static updateShowcase(data) {
    return request({
      url: "api/v2/user/design/edit",
      method: "PUT",
      data
    })
  }
  // 获取橱窗详情
  static getShowcaseDetail({ showcaseId, translate_params }) {
    return request({
      url: `api/v2/user/design/detail/${showcaseId}`,
      method: "GET",
      params: { translate_params }
    })
  }
  /**
   * 获取h5上传二维码
   * @static
   * @param {object} params
   * @param {number} params.uid
   * @param {string} params.language 中文zh 英文en 日文ja
   * @param {number} params.type 默认1印花, 2材质
   */
  static getUploadQrCode(params) {
    return request({
      url: "api/user/file_upload/qrcode_h5_v2",
      method: "GET",
      params
    })
  }

  /**
   * 获取已经传的图片
   * @static
   * @param {object} params
   * @param {number} params.uid
   * @param {string} params.start_time 时间戳, 获取在这个时间后上传的图片
   * @param {number} params.type 默认1印花, 2材质
   * @param {number} params.page 页码
   * @param {number} params.page_size 每页几条数据
   */
  static getUploadList(params) {
    return request({
      url: "api/user/file_upload/list",
      method: "GET",
      params
    })
  }
}
