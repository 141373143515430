import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { getCurrentInstance, computed } from "vue";
export default {
  __name: 'golf',
  props: {
    width: {
      type: String,
      default: "400"
    },
    height: {
      type: String,
      default: "auto"
    },
    backgroundColor: {
      type: String,
      default: "#fff"
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _getCurrentInstance = getCurrentInstance(),
      proxy = _getCurrentInstance.proxy;
    var loadingStyle = computed(function () {
      var style = {};
      for (var attr in props) {
        if (attr === "width" || attr === "height" && props[attr] != "auto") {
          style[attr] = proxy.$px2rem(props[attr]);
        }
      }
      return style;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "container",
        style: _normalizeStyle({
          backgroundColor: __props.backgroundColor
        })
      }, [_createElementVNode("img", {
        style: _normalizeStyle(_unref(loadingStyle)),
        src: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/03/you_mei_shang_applet/gole-loading.png",
        alt: ""
      }, null, 4)], 4);
    };
  }
};